import { setDate } from "date-fns";
import React, { useEffect, useState, updateState } from "react";
import { Badge, Button, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { withNamespaces } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import "~/assets/css/icons.css";
import ButtonDownload from "~/components/Buttons/DownloadButton";
import ButtonUpload from "~/components/Buttons/UploadButton";
import InputDate from "~/components/InputDate";
import pagination from "~/components/pagination/currentSizePerPage/10";
import Spinner from "~/components/Spinner";
import WarningModal from "~/components/WarningModal/WarningModal";
import AutomatedDocumentTableFilters from "./AutomatedDocumentTableFilters";
import { Card, Tabs, Tab } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import {
  deleteDocument,
  findAllBatchs,
  getMetadataDocument,
  submitToiFlow,
  updateApproved,
  resetApproved,
  refreshApprovedDate,
  setBatchs,
  clearBatchs,
} from "~/pages/DataCapture/actions";
import "~/pages/DataCapture/scss/datacapture.scss";
import { DocumentStatus } from "~/pages/DataCapture/utils/DataCaptureEnum";
import {
  toLocaleDateString,
  buildDocNumberColumn,
  buildVendorNameColum,
  buildTotalAmoutColumn,
} from "~/utils";
import {
  getColorByStatusDocument,
  translateStatusDocument,
  translateApprovedDocument,
  getColorByApprovedDocument,
} from "../utils";
import { ADocumentStatus } from "../utils/ADatacaptureEnum";
import "./AutomatedDocumentTable.css";
import DigestModal from "./DigestModal";
import ExportModal from "./ExportModal";
import MetadataPreviewModal from "./MetadataPreviewModal";
import UploadModal from "./UploadModal";
import NonFinAutomatedDocumentTable from "./NonFinAutomatedDocumentTable.js";
import MobileCardDataCapture from "../utils/MobileCardDataCapture";

const AutomatedDocumentTable = ({
  t,
  openSettingsModal,
  simpleFlow,
  datacaptureConfiguration,
  showUploadModal,
  setShowUploadModal,
  setFromTabUploadModal,
  defaultTab,
  setDocTypeSelected,
  docTypeExtraConfiguration,
  setShowUploadModalOpenAi,
  setSelectedDocType,
  setDatasetSelected,
}) => {
  const dispatch = useDispatch();
  const { batchs, isUpdatedDocuments, isLoadingBatchs, changed } = useSelector(
    (state) => state.dataCaptureReducer
  );

  const { configuration } = useSelector((state) => state.adminConfigReducer);
  const { isRefreshing, isSavingMetadataDocument } = useSelector(
    (state) => state.dataCaptureReducer
  );
  const { user } = useSelector((state) => state.globalReducer);
  const { userSettings } = useSelector((state) => state.userSettingsReducer);
  const language = userSettings.language;

  const isProductionMode = configuration ? configuration.productionMode : true;

  const { organization } = useSelector((state) => state.organizationsReducer);
  const { openAIConfiguration } = useSelector(
    (state) => state.rhadminConfigurationReducer
  );

  const [showMetadataModal, setShowMetadataModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [showExportModal, setShowExportModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDigestModal, setShowDigestModal] = useState(false);
  const [showDigest, setShowDigest] = useState(false);
  const [showTerminated, setShowTerminated] = useState(false);
  const [filtroObj, setFiltroObj] = useState(undefined);
  const [showApprovedModal, setShowApprovedModal] = useState(false);
  const [tempRow, setTempRow] = useState(undefined);
  const [forceFiltersReset, setForceFiltersReset] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [columnsTable, setcolumnsTable] = useState([]);
  const [documentsForTable, setdocumentsForTable] = useState(null);
  const [docsToShow, setDocsToShow] = useState(null);
  const [activeButton, setActiveButton] = useState("ALL");
  const [suplierSearch, setSuplierSearch] = useState("");
  const [docsToShowCopyforSuplier, setDocsToShowCopyforSuplier] =
    useState(null);
  const [filterActive, setFilterActive] = useState(false);

  useEffect(() => {
    if (
      Object.keys(openAIConfiguration).length > 0 &&
      openAIConfiguration.datasets &&
      openAIConfiguration.datasets.length > 0
    ) {
      if (
        docTypeExtraConfiguration.length > 0 &&
        docTypeExtraConfiguration.length === 1
      ) {
        const dataset = openAIConfiguration.datasets.find(
          (dataset) =>
            dataset.documentType &&
            docTypeExtraConfiguration.some((docType) =>
              dataset.documentType.includes(docType)
            )
        );
        setDatasetSelected(dataset);
        setSelectedDocType(dataset.documentType);
      }
    }
  }, [docTypeExtraConfiguration, openAIConfiguration]);

  useEffect(() => {
    if (simpleFlow) {
      if (user && user.organization) {
        setShowDigest(user?.organization?.emailDigest);
      }
    } else {
      setShowDigest(false);
    }
  }, [user, simpleFlow]);

  const updateAfterDelete = () => {
    dispatch(findAllBatchs());
    setShowMetadataModal(false);
  };

  useEffect(() => {
    if (changed) {
      dispatch(findAllBatchs());
      setShowMetadataModal(false);
      dispatch(resetApproved());
    }
  }, [changed]);

  useEffect(() => {
    searchSuplier();
  }, [suplierSearch, docsToShowCopyforSuplier]);

  const searchSuplier = () => {
    if (suplierSearch == "") {
      setDocsToShow(docsToShowCopyforSuplier);
    } else {
      const filteredDocs = docsToShowCopyforSuplier?.filter((doc) =>
        doc?.metadata?.supplier_name
          .toLowerCase()
          .includes(suplierSearch.toLowerCase())
      );
      setDocsToShow(filteredDocs);
    }
  };

  const cron = [
    { label: "Every 1 hour", value: "0 * * * *" },
    { label: "Every 2 hours", value: "0 */2 * * *" },
    { label: "Every 4 hours", value: "*/4 * * *" },
    { label: "Every 8 hours", value: "*/8 * * *" },
    { label: "Daily", value: "0 8 * * *", selected: true },
    { label: "Weekly", value: "0 8 * * MON" },
  ];

  const getFrequency = (value, cron) =>
    cron.find((e) => e.value === value)?.label;

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleCloseDetailModal = () => {
    setShowMetadataModal(false);
  };
  const handleCloseExportModal = () => {
    setShowExportModal(false);
    if (isUpdatedDocuments) dispatch(findAllBatchs());
  };
  const handleCloseUploadModal = () => {
    setShowUploadModal(false);
    setFromTabUploadModal(defaultTab);
  };
  const handleCloseDigestModal = () => {
    setShowDigestModal(false);
  };

  const handleOpenMetadataModal = (row) => {
    setShowMetadataModal(true);
    setSelectedDocument(row);
    dispatch(getMetadataDocument(row.id));
  };

  const getApproved = (row) => {
    return row?.approved
      ? ADocumentStatus.PROCESSED
      : ADocumentStatus.REJECTED_IFLOW;
  };

  const getStatus = (row) => {
    let status = ADocumentStatus.ERROR;
    const processing = [
      DocumentStatus.PENDING,
      DocumentStatus.LOADED,
      DocumentStatus.WAITING_SUBMISSION,
      ADocumentStatus.PENDING_IFLOW,
      ADocumentStatus.PENDING_REJ_IFLOW,
    ];
    const error = [DocumentStatus.FAILED, DocumentStatus.REJECTED];
    const processed = [
      DocumentStatus.SUCCESS,
      DocumentStatus.REVISED,
      DocumentStatus.REVIEWED,
    ];

    if (processed.some((item) => item === row.statusV1)) {
      if (
        row.isDuplicated &&
        row.isDuplicated !== false &&
        row.isDuplicated !== "Duplicate Accepted" &&
        simpleFlow
      ) {
        status = ADocumentStatus.DUPLICATED;
      } else {
        status = ADocumentStatus.PROCESSED;
      }
    } else if (processing.some((item) => item === row.statusV1))
      status = ADocumentStatus.PROCESSING;
    else if (error.some((item) => item === row.statusV1))
      status = ADocumentStatus.ERROR;
    else if (row.statusV1 === ADocumentStatus.TERMINATED)
      status = ADocumentStatus.TERMINATED;
    else if (row.statusV1 === ADocumentStatus.REJECTED_IFLOW)
      status = ADocumentStatus.REJECTED_IFLOW;
    return status;
  };

  const buildStatusColumn = (cell, row) => {
    const status = getStatus(row);
    const variant = getColorByStatusDocument(status);
    const translatedStatus = translateStatusDocument(status);
    return (
      <Badge pill variant={variant}>
        {t(translatedStatus)}
      </Badge>
    );
  };

  const handleOnClickApproved = (row) => {
    if (
      translateStatusDocument(getStatus(row)) ===
      "datacapture.automated.document_status.processed"
    ) {
      if (row?.approved && row?.paymentDate !== undefined) {
        setTempRow(row);
        setShowApprovedModal(true);
      } else {
        setShowApprovedModal(false);
        let batchId = row?.batchId;
        let documentId = row?.id;
        dispatch(updateApproved(batchId, documentId));
      }
    }
  };

  const buildApprovedColumn = (cell, row) => {
    const status = getApproved(row);
    const variant = getColorByApprovedDocument(status);
    const translatedStatus = translateApprovedDocument(status);
    return translateStatusDocument(getStatus(row)) !==
      "datacapture.automated.document_status.processed" ? (
      <OverlayTrigger
        placement="right"
        overlay={renderTooltip(t("datacapture.general.approved_info"))}
      >
        <Badge
          pill
          variant={variant}
          onClick={() => handleOnClickApproved(row)}
        >
          {t(translatedStatus)}
        </Badge>
      </OverlayTrigger>
    ) : (
      <Badge pill variant={variant} onClick={() => handleOnClickApproved(row)}>
        {t(translatedStatus)}
      </Badge>
    );
  };

  const handleDateChange = (row, datesArr) => {
    let batchId = row?.batchId;
    let documentId = row?.id;
    var newDate = datesArr[0]?.toISOString();
    dispatch(refreshApprovedDate(batchId, documentId, newDate));
  };

  const buildApprovedSort = (cell, row) => {
    const status = getApproved(row);
    return translateApprovedDocument(status);
  };

  const buildStatusSort = (cell, row) => {
    const status = getStatus(row);
    return translateStatusDocument(status);
  };

  const buildDocTypeColumn = (cell, row) => {
    return (
      <InputDate
        disabled={!row?.approved}
        dates={[
          row?.paymentDate !== undefined
            ? new Date(row?.paymentDate)
            : undefined,
        ]}
        handleChange={(value) => handleDateChange(row, value.data)}
        className="calendar-automation"
      />
    );
  };

  const buildDocTypeColumnModal = (cell, row) => {
    if (
      row.metadata &&
      (row.metadata["documentClass"] ||
        (row.metadata && row.metadata["class"]) ||
        row.metadata["document_type"] ||
        row.metadata["document:class"])
    ) {
      function checkForHuman(value) {
        return value.includes("human") ? true : false;
      }
      if (row.metadata["documentClass"])
        return checkForHuman(row.metadata["documentClass"])
          ? ""
          : row.metadata["documentClass"];
      else if (row.metadata["class"])
        return checkForHuman(row.metadata["class"])
          ? ""
          : row.metadata["class"];
      else if (row.metadata["document_type"])
        return checkForHuman(row.metadata["document_type"])
          ? ""
          : row.metadata["document_type"];
      else if (row.metadata["document:class"])
        return checkForHuman(row.metadata["document:class"])
          ? ""
          : row.metadata["document:class"];
    } else return "";
  };

  const checkIfBetween = (from, to, auxcheck) => {
    if (auxcheck === undefined) {
      return false;
    }
    let check = new Date(auxcheck);
    if (check <= to && check >= from) {
      return true;
    }
    return false;
  };

  const isValidFilteredDoc = (filtroObj, doc) => {
    const {
      createdStart,
      createdEnd,
      dataFaturaIncial,
      dataFaturaFinal,
      dataPagamentoInicial,
      dataPagamentoFinal,
      approvedRadioValue,
    } = filtroObj;

    let isValid = true;
    if (
      createdStart !== undefined &&
      createdStart !== null &&
      (createdEnd !== null) & (createdEnd !== undefined)
    ) {
      isValid = checkIfBetween(createdStart, createdEnd, doc?.createdDate);
      if (!isValid) return false;
    }
    if (
      dataFaturaIncial !== undefined &&
      dataFaturaIncial !== null &&
      (dataFaturaFinal !== null) & (dataFaturaFinal !== undefined)
    ) {
      isValid = checkIfBetween(
        dataFaturaIncial,
        dataFaturaFinal,
        doc?.metadata?.issue_date
      );
      if (!isValid) return false;
    }

    if (
      dataPagamentoInicial !== undefined &&
      dataPagamentoInicial !== null &&
      dataPagamentoFinal !== null &&
      dataPagamentoFinal !== undefined
    ) {
      isValid = checkIfBetween(
        dataPagamentoInicial,
        dataPagamentoFinal,
        doc?.paymentDate
      );
      if (!isValid) return false;
    }

    if (approvedRadioValue !== "0") {
      if (approvedRadioValue === "1") {
        isValid = doc?.approved == false;
        if (!isValid) return false;
      }
      if (approvedRadioValue === "2") {
        isValid = doc?.approved == true;
        if (!isValid) return false;
      }
      if (approvedRadioValue === "3") {
        isValid = doc?.paymentDate !== undefined;
        if (!isValid) return false;
      }
    }

    return isValid;
  };

  const batchs_ = batchs.sort(
    (a, b) => new Date(b.createdDate) - new Date(a.createdDate)
  );
  //TODO show terminated only or without it
  let docs = [];
  const updatedDocs = (filtroObj) => {
    if (filtroObj != undefined) {
      const { approvedRadioValue, ...newObj } = filtroObj;
      const filteredObj = Object.fromEntries(
        Object.entries(newObj).filter(([key, value]) => value !== undefined)
      );

      if (Object.keys(filteredObj).length > 0) {
        setFilterActive(true);
      } else {
        if (approvedRadioValue != "0") {
          setFilterActive(true);
        } else setFilterActive(false);
      }
    }
    batchs_.forEach((batch) => {
      batch.documents.forEach((doc) => {
        doc.batchFlow = batch.flow;
        doc.batchId = batch.id;
        /*doc._datcaptureConfig is used to submit for iFlow, 
      since  bootstrap table is not updating after datacaptureConfiguration changes*/
        doc._datcaptureConfig =
          datacaptureConfiguration &&
          datacaptureConfiguration.find((c) => c.documentType === batch.flow);
        if (filtroObj !== undefined) {
          if (doc.statusV1 === DocumentStatus.TERMINATED) {
            if (showTerminated) {
              if (
                isValidFilteredDoc(filtroObj, doc) &&
                doc?.metadata?.class === "financial-document"
              )
                docs.push(doc);
            }
          } else {
            if (
              isValidFilteredDoc(filtroObj, doc) &&
              doc?.batchFlow === "financial-document"
            )
              docs.push(doc);
          }
        } else {
          if (doc.statusV1 === DocumentStatus.TERMINATED) {
            if (showTerminated && doc?.metadata?.class === "financial-document")
              docs.push(doc);
          } else {
            if (doc?.metadata?.class === undefined) {
              if (doc?.batchFlow === "financial-document") {
                docs.push(doc);
              }
            } else {
              if (doc?.metadata?.class === "financial-document") docs.push(doc);
            }
          }
        }
      });
    });
    setdocumentsForTable(docs);
    setDocsToShow(docs);
  };

  useEffect(() => {
    updatedDocs(filtroObj);
  }, [batchs]);

  useEffect(() => {
    handleChangeColumns(activeButton);
  }, [documentsForTable, language]);
  const reviewedDocs = docsToShow?.filter((doc) => doc.statusV1 === "REVIEWED");
  const reviewedWithoutDuplicates = reviewedDocs?.filter(
    (doc) =>
      doc.isDuplicated === "Duplicate Accepted" || doc.isDuplicated === ""
  );

  function renderTooltip(text, ...props) {
    return (
      <Tooltip id="button-tooltip" {...props}>
        {text}
      </Tooltip>
    );
  }

  const canSubmitToIflow = (batchId) => {
    let cansubmit = false;
    const savedBatch = batchs.find((b) => b.id === batchId);
    if (savedBatch) {
      cansubmit = true;
      savedBatch.documents.forEach((doc) => {
        if (doc.statusV1 !== ADocumentStatus.REJECTED_IFLOW) {
          //TODO or Terminated
          cansubmit = false;
        }
      });
    }
    return cansubmit;
  };

  const handleSubmitToiFlow = (e, row) => {
    e.stopPropagation();
    e.preventDefault();
    if (canSubmitToIflow(row.batchId)) {
      const flowId = row._datcaptureConfig && row._datcaptureConfig.flowId;
      flowId && dispatch(submitToiFlow(row, flowId));
    }
  };

  const buildActionsColumn = (_, row, {}) => (
    <>
      {(DocumentStatus?.REVISED === row?.statusV1 ||
        ADocumentStatus?.TERMINATED === row?.statusV1 ||
        DocumentStatus?.REVIEWED === row?.statusV1) && (
        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(t("datacapture.general.detailDocument"))}
        >
          <i
            className="icon-detail table-action-icon"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleOpenMetadataModal(row, false);
            }}
          />
        </OverlayTrigger>
      )}
      {(DocumentStatus?.PENDING === row?.statusV1 ||
        DocumentStatus?.WAITING_SUBMISSION === row?.statusV1 ||
        DocumentStatus.LOADED === row?.statusV1 ||
        DocumentStatus.FAILED === row?.statusV1 ||
        DocumentStatus.REJECTED === row?.statusV1 ||
        ADocumentStatus.PENDING_IFLOW === row?.statusV1) && (
        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(t("datacapture.general.detailDocument"))}
        >
          <i
            className="icon-detail table-action-icon"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleOpenMetadataModal(row, false);
            }}
          />
        </OverlayTrigger>
      )}
      {ADocumentStatus?.REJECTED_IFLOW === row?.statusV1 && (
        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(t("datacapture.general.submitBatch"))}
        >
          <i
            className="icon-submit table-action-icon"
            onClick={(e) => handleSubmitToiFlow(e, row)}
          />
        </OverlayTrigger>
      )}
    </>
  );
  const calculateSlaTime = (_, row, {}) => {
    const createdDate = new Date(row.createdDate).getTime();
    const revisionDate = new Date(row.revisionDate).getTime();
    const slaSeconds = (revisionDate - createdDate) / 1000;

    if (slaSeconds >= 60 && slaSeconds < 120) {
      return `${slaSeconds}sec`;
    }
    if (slaSeconds >= 120 && slaSeconds < 3600) {
      return `${Math.round(slaSeconds / 60)}min`;
    }
    if (slaSeconds >= 3600 && slaSeconds < 86400) {
      return `${Math.floor(slaSeconds / 3600)}h ${Math.round(
        ((slaSeconds / 3600) % 1) * 60
      )}min `;
    }
    if (slaSeconds >= 86400) {
      return `${Math.floor(slaSeconds / 86400)}d ${Math.round(
        ((slaSeconds / 86400) % 1) * 60
      )}h `;
    }
  };

  const isMobile = window.innerWidth < 992 ? true : false;

  const handleShowMetadataModal = (bollean) => {
    if (bollean) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  };

  const getMobileCardFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <MobileCardDataCapture
        row={row}
        rowIndex={rowIndex}
        formatExtraData={formatExtraData}
        buildStatusColumn={buildStatusColumn}
        buildApprovedColumn={buildApprovedColumn}
        buildDocTypeColumn={buildDocTypeColumn}
        buildActionsColumn={buildActionsColumn}
        handleOpenMetadataModal={handleOpenMetadataModal}
        handleShowMetadataModal={handleShowMetadataModal}
      />
    );
  };

  const mobileColumns = [
    {
      dataField: "mobileCard",
      text: "",
      formatter: getMobileCardFormatter,
      // formatExtraData: {
      //   MetadataPreviewModal: MetadataPreviewModal,
      // },
      hidden: isMobile ? false : true,
      headerStyle: { display: "none" },
      style: { padding: "0px" },
    },
  ];
  const columns = [
    {
      dataField: "statusV1",
      text: t("datacapture.general.status"),
      sort: true,
      formatter: (cell, row) => buildStatusColumn(cell, row),
      sortValue: (cell, row) => buildStatusSort(cell, row),
      headerStyle: () => {
        return { textAlign: "center", width: "130px" };
      },
      align: "center",
      // hidden: isMobile ? false : true,
      csvExport: false,
    },
    {
      dataField: "metadata.issue_date",
      text: t("datacapture.general.paymentDate"),
      sort: true,
      formatter: (_, row, {}) => toLocaleDateString(row?.metadata?.issue_date),
      csvFormatter: (cell, row, rowIndex) =>
        toLocaleDateString(row.createdDate).replace(/,/g, ""),
      headerStyle: () => {
        return { textAlign: "center" };
      },
      align: "center",
    },
    {
      dataField: "metadata.document_identifier",
      text: t("datacapture.general.payrollNumber"),
      sort: true,
      formatter: (cell, row) => buildDocNumberColumn(cell, row),
      sortValue: (cell, row) => buildDocNumberColumn(cell, row),
      headerStyle: () => {
        return { textAlign: "center" };
      },
      align: "center",
    },
    {
      dataField: "metadata.supplier_name",
      text: t("datacapture.general.suplier"),
      sort: true,
      formatter: (cell, row) => buildVendorNameColum(cell, row),
      sortValue: (cell, row) => buildVendorNameColum(cell, row),
      headerStyle: () => {
        return { textAlign: "center", width: "20%" };
      },
      style: () => {
        return { textAlign: "center", width: "20%" };
      },
      align: "center",
    },

    {
      dataField: "metadata.amount_total",
      text: t("datacapture.general.totalPayed"),
      sort: true,
      formatter: (cell, row) => buildTotalAmoutColumn(cell, row),
      sortValue: (cell, row) => buildTotalAmoutColumn(cell, row),
      headerStyle: () => {
        return { textAlign: "center" };
      },
      align: "center",
    },
    {
      dataField: "approved",
      text: t("datacapture.filter.aproved"),
      sort: true,
      formatter: (cell, row) => buildApprovedColumn(cell, row),
      sortValue: (cell, row) => buildApprovedSort(cell, row),
      headerStyle: () => {
        return { textAlign: "center" };
      },
      align: "center",
    },
    {
      dataField: "paymentDate",
      text: t("datacapture.general.paymentDateSubmitted"),
      sort: true,
      formatter: (cell, row) => buildDocTypeColumn(cell, row),
      sortValue: (cell, row) => buildDocTypeColumn(cell, row),
      headerStyle: () => {
        return { textAlign: "center" };
      },
      align: "center",
    },
    {
      dataField: "actions",
      text: t("datacapture.general.actions"),
      formatter: buildActionsColumn,
      align: "center",
      headerStyle: () => {
        return { textAlign: "center", width: "100px" };
      },
    },
    {
      dataField: "metadata.sepa",
      text: t("datacapture.general.sepa"),
      sort: true,
      formatter: (cell, row) => "",
      sortValue: (cell, row) => "",
      headerStyle: () => {
        return { textAlign: "center", color: "#cccccc" };
      },
      align: "center",
    },
    {
      dataField: "metadata.ccusto",
      text: t("datacapture.general.ccusto"),
      sort: true,
      formatter: (cell, row) => "",
      sortValue: (cell, row) => "",
      headerStyle: () => {
        return { textAlign: "center", color: "#cccccc" };
      },
      align: "center",
    },
  ];

  useEffect(() => {
    setcolumnsTable(columns);
    setDocsToShow(docs);
    setDocsToShowCopyforSuplier(docs);
  }, []);

  if (!isProductionMode) {
    const buildDelete = (_, row, {}) => {
      return (
        <button
          className="button-delete-document"
          onClick={() => {
            dispatch(deleteDocument(row.id));
          }}
        >
          Delete
        </button>
      );
    };
    const deleteColumn = {
      dataField: "delete",
      text: "Delete",
      formatter: buildDelete,
      headerStyle: () => {
        return { textAlign: "center", width: "100px" };
      },
      align: "center",
      csvExport: false,
    };

    const slaColumn = {
      dataField: "sla",
      text: t("admin.organization.list.sla"),
      formatter: calculateSlaTime,
      headerStyle: () => {
        return { width: "130px", textAlign: "center" };
      },
    };
    columns.push(slaColumn);
    columns.push(deleteColumn);
  }

  const MyExport = () => {
    const handleClick = () => {
      setShowExportModal(true);
    };
    return (
      <div style={{ marginLeft: "10px" }}>
        <ButtonDownload
          text={t("datacapture.general.downloadDocuments")}
          onClick={handleClick}
        />
      </div>
    );
  };

  const { SearchBar } = Search;

  const handleSearchTable = (filtroObj) => {
    setFiltroObj(filtroObj);
    updatedDocs(filtroObj);
    searchSuplier();
  };

  const handleResetSearch = () => {
    setForceFiltersReset(true);
    setFiltroObj(undefined);
    dispatch(findAllBatchs());
    setShowMetadataModal(false);
    updatedDocs();
  };

  const handleClose = () => setShowApprovedModal(false);
  const handleAccept = () => {
    let batchId = tempRow?.batchId;
    let documentId = tempRow?.id;
    setTempRow(undefined);
    dispatch(updateApproved(batchId, documentId));
    handleClose();
  };

  datacaptureConfiguration = datacaptureConfiguration.filter(
    (e) => !e?.deprecated
  );
  const columns_Rejected = [
    {
      dataField: "filename",
      text: t("datacapture.general.filename"),
      sort: true,
      formatter: (cell, row) => (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id={`tooltip-${row.id}`}>{row?.filename}</Tooltip>}
        >
          <span className="filename-text">
            {" "}
            {row?.filename.length > 14
              ? row?.filename.substring(0, 14) + "..."
              : row?.filename}
          </span>
        </OverlayTrigger>
      ),
      headerStyle: () => {
        return { textAlign: "center" };
      },
      align: "center",
    },
    {
      dataField: "createdDate",
      text: t("datacapture.general.createdDate"),
      sort: true,
      formatter: (_, row, {}) => toLocaleDateString(row?.createdDate),
      csvFormatter: (cell, row, rowIndex) =>
        toLocaleDateString(row.createdDate).replace(/,/g, ""),
      headerStyle: () => {
        return { textAlign: "center" };
      },
      align: "center",
    },
    {
      dataField: "countSubmissionTries",
      text: t("datacapture.general.countSubmissionTries"),
      sort: true,
      formatter: (cell, row) => row?.countSubmissionTries,
      headerStyle: () => {
        return { textAlign: "center" };
      },
      align: "center",
    },
    {
      dataField: "originalFileName",
      text: t("datacapture.general.actions"),
      formatter: buildActionsColumn,
      align: "center",
      headerStyle: () => {
        return { textAlign: "center", width: "100px" };
      },
    },
  ];
  const columns_Waiting = [
    {
      dataField: "filename",
      text: t("datacapture.general.filename"),
      sort: true,
      formatter: (cell, row) => (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id={`tooltip-${row.id}`}>{row?.filename}</Tooltip>}
        >
          <span className="filename-text">
            {row?.filename.length > 14
              ? row?.filename.substring(0, 14) + "..."
              : row?.filename}
          </span>
        </OverlayTrigger>
      ),
      headerStyle: () => {
        return { textAlign: "center" };
      },
      align: "center",
    },
    {
      dataField: "createdDate",
      text: t("datacapture.general.createdDate"),
      sort: true,
      formatter: (_, row, {}) => toLocaleDateString(row?.createdDate),
      csvFormatter: (cell, row, rowIndex) =>
        toLocaleDateString(row.createdDate).replace(/,/g, ""),
      headerStyle: () => {
        return { textAlign: "center" };
      },
      align: "center",
    },
    {
      dataField: "originalFileName",
      text: t("datacapture.general.actions"),
      formatter: buildActionsColumn,
      align: "center",
      headerStyle: () => {
        return { textAlign: "center", width: "100px" };
      },
    },
  ];

  const columns_Approved = [
    {
      dataField: "filename",
      text: t("datacapture.general.filename"),
      sort: true,
      formatter: (cell, row) => (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id={`tooltip-${row.id}`}>{row?.filename}</Tooltip>}
        >
          <span className="filename-text">
            {row?.filename.length > 14
              ? row?.filename.substring(0, 14) + "..."
              : row?.filename}
          </span>
        </OverlayTrigger>
      ),
      headerStyle: () => {
        return { textAlign: "center" };
      },
      align: "center",
    },
    {
      dataField: "metadata.issue_date",
      text: t("datacapture.general.paymentDate"),
      sort: true,
      formatter: (_, row, {}) => toLocaleDateString(row?.metadata?.issue_date),
      csvFormatter: (cell, row, rowIndex) =>
        toLocaleDateString(row.createdDate).replace(/,/g, ""),
      headerStyle: () => {
        return { textAlign: "center" };
      },
      align: "center",
    },
    {
      dataField: "metadata.document_identifier",
      text: t("datacapture.general.payrollNumber"),
      sort: true,
      formatter: (cell, row) => buildDocNumberColumn(cell, row),
      sortValue: (cell, row) => buildDocNumberColumn(cell, row),
      headerStyle: () => {
        return { textAlign: "center" };
      },
      align: "center",
    },
    {
      dataField: "metadata.supplier_name",
      text: t("datacapture.general.suplier"),
      sort: true,
      formatter: (cell, row) => buildVendorNameColum(cell, row),
      sortValue: (cell, row) => buildVendorNameColum(cell, row),
      headerStyle: () => {
        return { textAlign: "center", width: "20%" };
      },
      style: () => {
        return { textAlign: "center", width: "20%" };
      },
      align: "center",
    },
    {
      dataField: "metadata.amount_total",
      text: t("datacapture.general.totalPayed"),
      sort: true,
      formatter: (cell, row) => buildTotalAmoutColumn(cell, row),
      sortValue: (cell, row) => buildTotalAmoutColumn(cell, row),
      headerStyle: () => {
        return { textAlign: "center" };
      },
      align: "center",
    },
    {
      dataField: "approved",
      text: t("datacapture.filter.aproved"),
      sort: true,
      formatter: (cell, row) => buildApprovedColumn(cell, row),
      sortValue: (cell, row) => buildApprovedSort(cell, row),
      headerStyle: () => {
        return { textAlign: "center" };
      },
      align: "center",
    },
    {
      dataField: "paymentDate",
      text: t("datacapture.general.paymentDateSubmitted"),
      sort: true,
      formatter: (cell, row) => buildDocTypeColumn(cell, row),
      sortValue: (cell, row) => buildDocTypeColumn(cell, row),
      headerStyle: () => {
        return { textAlign: "center" };
      },
      align: "center",
    },
    {
      dataField: "actions",
      text: t("datacapture.general.actions"),
      formatter: buildActionsColumn,
      align: "center",
      headerStyle: () => {
        return { textAlign: "center", width: "100px" };
      },
    },
    {
      dataField: "metadata.sepa",
      text: t("datacapture.general.sepa"),
      sort: true,
      formatter: (cell, row) => "",
      sortValue: (cell, row) => "",
      headerStyle: () => {
        return { textAlign: "center", color: "#cccccc" };
      },
      align: "center",
    },
    {
      dataField: "metadata.ccusto",
      text: t("datacapture.general.ccusto"),
      sort: true,
      formatter: (cell, row) => "",
      sortValue: (cell, row) => "",
      headerStyle: () => {
        return { textAlign: "center", color: "#cccccc" };
      },
      align: "center",
    },
  ];

  const handleChangeColumns = (value) => {
    switch (value) {
      case "REJECTED":
        setcolumnsTable(columns_Rejected);
        const filterfail = documentsForTable.filter(
          (doc) => doc?.statusV1 === "FAILED" || doc?.statusV1 === "REJECTED"
        );
        setDocsToShow(filterfail);
        setDocsToShowCopyforSuplier(filterfail);
        setActiveButton(value);
        break;
      case "PROCESSED":
        setcolumnsTable(columns_Approved);
        const filter = documentsForTable.filter(
          (doc) =>
            doc?.statusV1 === "SUCCESS" ||
            doc?.statusV1 === "REVISED" ||
            doc?.statusV1 === "REVIEWED"
        );
        setDocsToShow(filter);
        setDocsToShowCopyforSuplier(filter);
        setActiveButton(value);
        break;
      case "WAITING":
        setcolumnsTable(columns_Waiting);
        const filterWait = documentsForTable.filter(
          (doc) =>
            doc?.statusV1 === "PENDING" ||
            doc?.statusV1 === "LOADED" ||
            doc?.statusV1 === "WAITING_SUBMISSION" ||
            doc?.statusV1 === "PENDING_IFLOW"
        );
        setDocsToShow(filterWait);
        setDocsToShowCopyforSuplier(filterWait);
        setActiveButton(value);
        break;
      default:
        setcolumnsTable(columns);
        setDocsToShow(documentsForTable);
        setDocsToShowCopyforSuplier(documentsForTable);
        setActiveButton("ALL");
        break;
    }
  };
  return (
    <>
      {columnsTable && docsToShow && (
        <>
          <Modal show={showApprovedModal} onHide={handleClose} animation={true}>
            <Modal.Header closeButton>
              <Modal.Title>{t("datacapture.filter.changeState")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{t("datacapture.filter.changing")}</Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={handleClose}>
                {t("datacapture.filter.close")}
              </Button>
              <Button variant="success" onClick={handleAccept}>
                {t("datacapture.filter.accept")}
              </Button>
            </Modal.Footer>
          </Modal>
          <br></br>
          <div className="custom-table custom-table-small cursor-pointer">
            <ToolkitProvider
              keyField="id"
              data={docsToShow}
              columns={isMobile ? mobileColumns : columnsTable}
              search={{ searchFormatted: true }}
              style={{ display: "none" }}
              wrapperClasses="table-responsive"
            >
              {(props) => (
                <div>
                  <AutomatedDocumentTableFilters
                    forceFiltersReset={forceFiltersReset}
                    setForceFiltersReset={setForceFiltersReset}
                    onSearch={handleSearchTable}
                    onReset={handleResetSearch}
                    setSuplierSearch={setSuplierSearch}
                    suplierSearch={suplierSearch}
                    searchProps={props.searchProps}
                    filterActive={filterActive}
                    setFilterActive={setFilterActive}
                  />
                  <br></br>
                  <div className="dataCapture_container">
                    <div className="dataCapture_secondContainer">
                      <div className="mobile_group_buttons">
                        <div className="mobile_buttons">
                          <div className="button_upload">
                          <ButtonUpload
                                text={t("datacapture.general.upload")}
                                onClick={() => {
                                  setFromTabUploadModal("financial-document");
                                  setShowUploadModal(true);
                                  setDocTypeSelected("financial-document");
                                }}
                              />
                            {/* {organization &&
                            organization.datacaptureMode === "docD" ? (
                              <ButtonUpload
                                text={t("datacapture.general.upload")}
                                onClick={() => {
                                  setFromTabUploadModal("financial-document");
                                  setShowUploadModal(true);
                                  setDocTypeSelected("financial-document");
                                }}
                              />
                            ) : (
                              <ButtonUpload
                                text={t("datacapture.general.upload")}
                                onClick={() => {
                                  setShowUploadModalOpenAi(true);
                                }}
                              />
                            )} */}
                          </div>
                          <div className="button_export">
                            {docsToShow.length > 0 && simpleFlow && (
                              <MyExport />
                            )}
                          </div>
                        </div>
                        <div className="mobile_buttons">
                          {showDigest && (
                            <Button
                              className="card-button"
                              style={
                                isMobile
                                  ? { marginBottom: "10px" }
                                  : { marginLeft: "10px" }
                              }
                              onClick={() => openSettingsModal()}
                            >
                              {user?.settings?.digestFrequency
                                ? `Digest ${getFrequency(
                                    user.settings.digestFrequency,
                                    cron
                                  )}`
                                : "Digest Frequency"}
                            </Button>
                          )}

                          <Button
                            className="card-button"
                            style={
                              isMobile
                                ? { marginBottom: "10px" }
                                : { marginLeft: "10px" }
                            }
                            onClick={() => setShowDigestModal(true)}
                          >
                            Report
                          </Button>
                        </div>
                      </div>
                    </div>

                    {!simpleFlow && (
                      <Form.Check
                        type="switch"
                        id="custom-switch-terminated"
                        label={t("datacapture.general.showTerminated")}
                        style={{ marginBottom: "0.5rem", marginLeft: "10px" }}
                        checked={showTerminated}
                        onChange={() =>
                          setShowTerminated((showTerminated) => !showTerminated)
                        }
                      />
                    )}
                  </div>
                  <div style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                    <h6>{t("datacapture.general.status")}</h6>
                    <div className="dataCapture_container">
                      <Button
                        className={`card-button-orange ${
                          activeButton === "WAITING" ? "active" : ""
                        }`}
                        style={
                          isMobile
                            ? {
                                marginBottom: "10px",
                              }
                            : {
                                marginLeft: "0px",
                              }
                        }
                        onClick={() => handleChangeColumns("WAITING")}
                      >
                        {t("datacapture.general.processing")}
                      </Button>
                      <Button
                        className={`card-button-red ${
                          activeButton === "REJECTED" ? "active" : ""
                        }`}
                        style={
                          isMobile
                            ? {
                                marginBottom: "10px",
                              }
                            : { marginLeft: "10px" }
                        }
                        onClick={() => handleChangeColumns("REJECTED")}
                      >
                        {t("datacapture.general.rejected")}
                      </Button>
                      <Button
                        className={`card-button-green ${
                          activeButton === "PROCESSED" ? "active" : ""
                        }`}
                        style={
                          isMobile
                            ? {
                                marginBottom: "10px",
                              }
                            : {
                                marginLeft: "10px",
                              }
                        }
                        onClick={() => handleChangeColumns("PROCESSED")}
                      >
                        {t("datacapture.general.processed")}
                      </Button>
                      <Button
                        className={`card-button-all ${
                          activeButton === "ALL" ? "active" : ""
                        }`}
                        style={
                          isMobile
                            ? {
                                marginBottom: "10px",
                              }
                            : { marginLeft: "10px" }
                        }
                        onClick={() => handleChangeColumns("ALL")}
                      >
                        All
                      </Button>
                      <SearchBar
                        {...props.searchProps}
                        placeholder={t("general.search")}
                        style={
                          isMobile
                            ? {
                                marginBottom: "10px",
                              }
                            : { marginLeft: "10px" }
                        }
                      />
                    </div>
                  </div>
                  <div className="searchbar">
                    {isMobile
                      ? docsToShow.length > 0 && (
                          <SearchBar
                            {...props.searchProps}
                            placeholder={t("general.search")}
                          />
                        )
                      : null}
                  </div>
                  <BootstrapTable
                    {...props.baseProps}
                    id="document-table"
                    responsive
                    bootstrap4
                    pagination={paginationFactory(pagination)}
                    noDataIndication={t("datacapture.general.dataNotFound")}
                    pageSize={5}
                    striped={isMobile ? false : true}
                    hover={isMobile ? false : true}
                  />
                  <Spinner
                    spinning={
                      isRefreshing ||
                      isSavingMetadataDocument ||
                      isLoadingBatchs
                    }
                  />
                </div>
              )}
            </ToolkitProvider>
          </div>
          <ExportModal
            showModal={showExportModal}
            closeModal={handleCloseExportModal}
            reviewedDocs={reviewedWithoutDuplicates}
            buildDocTypeColumn={buildDocTypeColumnModal}
            datacaptureConfiguration={datacaptureConfiguration}
          />
          {/*<MetadataModal
  showModal={showMetadataModal}
  closeModal={handleCloseDetailModal}
  isEditing={isEditingMetadata}
  documents={docs}
  document={selectedDocument}
  menuId={selectedDocument && selectedDocument.menuId}
  updateAfterDelete={updateAfterDelete}
  simpleFlow={simpleFlow}
  />*/}
          <MetadataPreviewModal
            showModal={showMetadataModal}
            closeModal={handleCloseDetailModal}
            documents={docsToShow}
            document={selectedDocument}
            updateAfterDelete={updateAfterDelete}
            simpleFlow={simpleFlow}
            datacaptureConfiguration={datacaptureConfiguration}
          />
          {/*  <UploadModal
  docTypeSelected="financial-document"
  showUploadModal={showUploadModal}
  handleCloseUploadModal={handleCloseUploadModal}
  simpleFlow={simpleFlow}
  datacaptureConfiguration={datacaptureConfiguration}
  /> */}
          <WarningModal
            showDeleteModal={showDeleteModal}
            handleCloseDeleteModal={handleCloseDeleteModal}
          />
          <DigestModal
            showDigestModal={showDigestModal}
            handleCloseDigestModal={handleCloseDigestModal}
            reviewedDocs={reviewedDocs}
          />
        </>
      )}
    </>
  );
};
export default withNamespaces()(AutomatedDocumentTable);
