import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withNamespaces } from "react-i18next";
import { Card, OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import { abbreviateString } from "~/utils";
import CancelIcon from "@mui/icons-material/Cancel";
import pdfPreview from "~/assets/img/datacapture/pdf_preview.svg";

const GeneralDocumentOpenAi = ({
  selectedDocType,
  datasetSelected,
  handleCloseUploadModalOpenAi,
  experiencedUser,
  t,
}) => {
  const dispatch = useDispatch();

  const [onDragOver, setOnDragOver] = useState(false);
  const [maxFileSize, setMaxFileSize] = useState(1024 * 1024 * 15);
  const [maxFiles, setMaxFiles] = useState(experiencedUser ? 100 : 1);
  const [documents, setDocuments] = useState([]);
  const [warningMessage, setWarningMessage] = useState("");
  const [showWarning, setShowWarning] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [documentName, setDocumentName] = useState("");

  const inputFile = useRef(null);

  const { batch, isLoadingBatch } = useSelector(
    (state) => state.dataCaptureReducer
  );

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getPreviewImage = () => {
    const file = documents[0];
    if (file) {
      return file.type === "application/pdf"
        ? pdfPreview
        : URL.createObjectURL(file);
    } else {
      return document.filetype === "application/pdf"
        ? pdfPreview
        : "data:image/jpg;base64," + document.data;
    }
  };

  const handleRemoveFile = () => {
    setDocuments([]);
    setDocumentName("");
    if (inputFile.current) {
      inputFile.current.value = "";
    }
  };

  const handleResetState = () => {
    handleRemoveFile();
    handleCloseUploadModalOpenAi();
  };

  const handleOnChoose = (event) => {
    const selectedFile = event.target.files[0];
    if (
      selectedFile.type === "image/jpeg" ||
      selectedFile.type === "image/png" ||
      selectedFile.type === "image/jpg" ||
      selectedFile.type === "application/pdf"
    ) {
      handleMultipleFiles([selectedFile]);
    } else {
      setWarningMessage(t("portalrh.expenses.fileType"));
    }
  };

  const handleOnDrop = (event) => {
    setOnDragOver(false);
    const files_ = event.dataTransfer.files;
    const files = [];
    for (let i = 0; i < files_.length; i++) {
      files.push(files_.item(i));
    }
    handleMultipleFiles(files);
    event.preventDefault();
  };

  const handleMultipleFiles = (files) => {
    let totalfilesize = files.reduce(
      (accumulator, currentValue) => accumulator + currentValue.size,
      0
    );
    if (totalfilesize > maxFileSize) {
      setWarningMessage(t("portalrh.expenses.fileSize"));
      return;
    }
    setDocuments(files);
    setDocumentName(files[0].name);
  };

  const handleClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  const handleSaveDocs = () => {
    const docList = [];
    documents.forEach((document, index, array) => {
      const reader = new FileReader();
      reader.onload = function (e) {
        docList.push({
          file: e.target.result,
        });
        if (index === array.length - 1) {
          // if (docList.length > 0) {
          //   const base64String = docList[0].file.split("base64,")[1];
          //   if (datasetSelected.extraction) {
          //     const payload = {
          //       userId: user.id,
          //       organizationId: user.organization.id,
          //       base64: base64String,
          //       docType: datasetSelected.documentType,
          //     };
          //     setImageBase64(base64String);
          //     dispatch(saveDocumentExpenses(payload));
          //   } else {
          //     let payload = {
          //       flowId: datasetSelected.flowId,
          //       base64: base64String,
          //       userId: user.id,
          //       organizationId: user.organization.id,
          //       fileName: documentName,
          //     };
          //     dispatch(postCreateDocumentExpenses(payload));
          //   }
          // }

          //COM START PROCESS
          //   const url = "/startProcess?Authorization=" + oauth.getAccessToken();
          //   const payloadtoGet = {
          //     flowid: flowid.flowId,
          //   };
          //   dispatch(postIflowForm(payloadtoGet, url));

          //COM INICIO_FLOW
          //   const payload = {
          //     url:
          //       "/inicio_flow.jsp?" +
          //       "flowid=" +
          //       flowId +
          //       "&attr_workplanId=" +
          //       workplans[deleteIndex]?.id +
          //       "&attr_Authorization=" +
          //       oauth.getAccessToken() +
          //       "&Authorization=" +
          //       oauth.getAccessToken(),
          //   };
          //   dispatch(getIflowFormdata(payload));
          setDocuments([]);
        }
      };
      reader.readAsDataURL(document);
    });
  };

  return (
    <>
      <div className="main-card-v2" style={{ display: "block" }}>
        <Card bsPrefix="card-flat">
          <Card.Header
            className="justify-content-between"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h4 style={isMobile ? { fontSize: "18px" } : { margin: "0" }}>
              {" "}
              {t("datacapture.general.fileUploadTitle")}
            </h4>
          </Card.Header>
          <Card.Body
            style={{ display: "flex", flexDirection: "column", padding: "0px" }}
          >
            <div
              style={{
                margin: "0px 0px 2rem",
                width: "100%",
                alignSelf: isMobile ? "auto" : "center",
              }}
            >
              <div style={{ display: !isMobile ? "flex" : "" }}>
                <div
                  className="dropAreaExpenses"
                  onDrop={(e) => handleOnDrop(e)}
                  onClick={() => handleClick()}
                  onDragEnter={() => setOnDragOver(true)}
                  onDragLeave={() => setOnDragOver(false)}
                  style={
                    onDragOver
                      ? { background: "#fff" }
                      : { background: "#efefef" }
                  }
                >
                  <span>
                    <i className={"icon-submit portal-image-upload-icon"} />
                    <div
                      className={"portal-label-main"}
                      style={{ padding: "2rem" }}
                    >
                      {t("portalrh.documentManagement.dropToUpload")}
                    </div>
                  </span>
                  <input
                    multiple={false}
                    id="file"
                    type="file"
                    ref={inputFile}
                    style={{ display: "none" }}
                    onChange={(e) => handleOnChoose(e)}
                    accept=".pdf, .png,.jpg,.jpeg"
                  />
                </div>
                {isMobile && (
                  <div className="dc-label">
                    {t("portalrh.documentManagement.totalFileSize") + " 15 Mb"}
                  </div>
                )}
                <div
                  style={{
                    display: "inline-block",
                    width: isMobile ? "100%" : "50%",
                    textAlignLast: "center",
                    marginTop: isMobile ? "1rem" : "",
                    height: "0px",
                  }}
                >
                  {(!isMobile || documents.length > 0) && (
                    <h6 style={{ placeContent: "center", padding: "0px" }}>
                      {t("openai.expenseLoaded")}
                    </h6>
                  )}
                  {documents.length > 0 && (
                    <>
                      <img
                        src={getPreviewImage()}
                        className={"portal-image-preview-one"}
                        alt="preview"
                      />
                      <CancelIcon color="error" onClick={handleRemoveFile} />
                      <OverlayTrigger
                        key="bottom"
                        placement="bottom"
                        overlay={<Tooltip>{documents[0].name}</Tooltip>}
                      >
                        <small>{abbreviateString(documents[0].name, 28)}</small>
                      </OverlayTrigger>
                    </>
                  )}
                </div>
              </div>
              {!isMobile && (
                <div className="dc-label">
                  {t("portalrh.documentManagement.totalFileSize") + " 15 Mb"}
                </div>
              )}
            </div>
          </Card.Body>
          <Card.Footer>
            <div
              style={{
                width: "100%",
                textAlignLast: "right",
                marginTop: "3rem",
              }}
            >
              <Button
                className="card-button"
                disabled={
                  documents.length > 0 && selectedDocType != "" ? false : true
                }
                onClick={() => {
                  documents.length > 0 && handleSaveDocs();
                }}
              >
                {t("openai.upload")}
              </Button>
            </div>
          </Card.Footer>
        </Card>
      </div>
    </>
  );
};

export default withNamespaces()(GeneralDocumentOpenAi);
